import {
    group_list,
    group_user_list,
    group_users,
    group_owner_info,
    group_machine_statistic,
    group_machine_bar_statistic,
    group_trade_statistic,
    group_trade_statistic_new,
    group_trade_line_statistic,
    add_group,
} from "@/api/group";

const state = {};
const mutations = {};
const actions = {
    // 小组列表-用于邀请员工
    group_list({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_list(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组列表-用于管理员工
    group_user_list({ commit }) {
        return new Promise((resolve, reject) => {
            group_user_list()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组员工列表
    group_users({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_users(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-小组负责人个人信息
    group_owner_info({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_owner_info(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-机器统计
    group_machine_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_machine_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-机器统计图表数据
    group_machine_bar_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_machine_bar_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-交易统计
    group_trade_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_trade_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-交易统计
    group_trade_statistic_new({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_trade_statistic_new(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 小组画像-交易统计图表数据
    group_trade_line_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            group_trade_line_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 新增小组
    add_group({ commit }, param) {
        return new Promise((resolve, reject) => {
            add_group(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
