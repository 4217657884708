import {
  uploadImg,
} from '@/api/upload'


const state = {}

const mutations = {}

const actions = {
  // 上传图片
  uploadImg({
    commit
  }, data) {
    return new Promise((resolve, reject) => {
      uploadImg(data).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
