import Vue from "vue";
//引入需要的组件
import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas);
import { Button } from "vant";
import { Cell, CellGroup } from "vant";
import { Swipe, SwipeItem } from "vant";
import { NoticeBar } from "vant";
import { Col, Row } from "vant";
import { NavBar } from "vant";
import { Toast } from "vant";
import { AddressList } from "vant";
import { Icon } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Area } from "vant";
import { Popup } from "vant";
import { Switch } from "vant";
import { Picker } from "vant";
import { Cascader } from "vant";
import { Lazyload } from "vant";
import { PullRefresh } from "vant";
import { List } from "vant";
import { Loading } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Progress } from "vant";
import { Search } from "vant";
import { Grid, GridItem } from "vant";
import { Image as VanImage } from "vant";
import { Tab, Tabs } from "vant";
import { DropdownMenu, DropdownItem } from "vant";
import { Empty } from "vant";
import { Stepper } from "vant";
import { Skeleton } from "vant";
import { Overlay } from "vant";
import { Dialog } from "vant";
import { Calendar } from 'vant';
import { DatetimePicker } from 'vant';
import { Uploader } from 'vant';

Vue.use(Uploader);

Vue.use(DatetimePicker);


Vue.use(Dialog);

Vue.use(Overlay);

Vue.use(Skeleton);

Vue.use(Stepper);
Vue.use(Empty);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Search);
Vue.use(Progress);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Loading);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Lazyload);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(Popup);
Vue.use(Area);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(AddressList);
Vue.use(Toast);
Vue.use(NavBar);
Vue.use(Col);
Vue.use(Row);
Vue.use(NoticeBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
