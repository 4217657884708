import { login, sms_login, logout, register, register_info } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { resetRouter } from "@/router";
// import { signature } from "@/utils/signdata";
// function clone(obj) {
//   let temp = null;
//   if (obj instanceof Array) {
//     temp = obj.concat();
//   } else if (obj instanceof Function) {
//     //函数是共享的是无所谓的，js也没有什么办法可以在定义后再修改函数内容
//     temp = obj;
//   } else {
//     temp = new Object();
//     for (let item in obj) {
//       let val = obj[item];
//       temp[item] = typeof val == 'object' ? clone(val) : val; //这里也没有判断是否为函数，因为对于函数，我们将它和一般值一样处理
//     }
//   }
//   return temp;
// }
const state = {
  token: getToken(),
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
};

const actions = {
  // user login
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then((response) => {
          commit(
            "SET_TOKEN",
            response.data.token_type + " " + response.data.access_token
          );
          setToken(response.data.token_type + " " + response.data.access_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user login
  sms_login({ commit }, data) {
    return new Promise((resolve, reject) => {
      sms_login(data)
        .then((response) => {
          commit(
            "SET_TOKEN",
            response.data.token_type + " " + response.data.access_token
          );
          setToken(response.data.token_type + " " + response.data.access_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then((response) => {
          commit("SET_TOKEN", "");
          removeToken();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  register({ commit }, data) {
    return new Promise((resolve, reject) => {
      register(data)
        .then((response) => {
          commit(
            "SET_TOKEN",
            response.data.token_type + " " + response.data.access_token
          );
          setToken(response.data.token_type + " " + response.data.access_token);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  register_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      register_info(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  // logout({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     logout(state.token)
  //       .then(() => {
  //         commit("SET_TOKEN", "");
  //         removeToken();
  //         // resetRouter()
  //         // commit('SET_ROLES', null)
  //         // setRoles(null);
  //         resolve();
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },
  // yzj_login({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     yzj_login(data)
  //       .then((response) => {
  //         resolve(response);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      removeToken();
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
