import Vue from "vue";
import Router from "vue-router";

const home = () => import("../views/home/Home.vue");
const edit_user_detail = () =>
  import("../views/edit_user_detail/edit_user_detail.vue");
const edit_password = () => import("../views/edit_password/edit_password.vue");
const register = () => import("../views/register/register.vue");
const department_admin = () =>
  import("../views/department_admin/department_admin.vue");
const grouping_admin = () =>
  import("../views/grouping_admin/grouping_admin.vue");
const grouping_detail = () =>
  import("../views/grouping_detail/grouping_detail.vue");
const department_portrait = () =>
  import("../views/department_portrait/department_portrait.vue");
const grouping_portrait = () =>
  import("../views/grouping_portrait/grouping_portrait.vue");
const person_portrait = () =>
  import("../views/person_portrait/person_portrait.vue");
const staff_portrait = () =>
  import("../views/staff_portrait/staff_portrait.vue");
const create_department = () =>
  import("../views/create_department/create_department.vue");
const invite_to_join = () =>
  import("../views/invite_to_join/invite_to_join.vue");
const invite_code = () => import("../views/invite_code/invite_code.vue");
const create_group = () => import("../views/create_group/create_group.vue");
const face_to_face = () => import("../views/face_to_face/face_to_face.vue");
const long_distance = () => import("../views/long_distance/long_distance.vue");
const take_delivery_detail = () =>
  import("../views/take_delivery_detail/take_delivery_detail.vue");
const logistics_detail = () =>
  import("../views/logistics_detail/logistics_detail.vue");
const client_admin = () => import("../views/client_admin/client_admin.vue");
const client_portrait = () =>
  import("../views/client_portrait/client_portrait.vue");
const company_portrait = () =>
  import("../views/company_portrait/company_portrait.vue");
const department_group_detail = () =>
  import("../views/department_group_detail/department_group_detail.vue");
const user_info = () => import("../views/user_info/user_info.vue");



const login = () => import("../views/login/login.vue");
Vue.use(Router);
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/register",
    component: register,
    meta: {
      title: "注册",
    },
  },
  {
    path: "/department_admin",
    component: department_admin,
    meta: {
      title: "部门管理",
    },
  },
  {
    path: "/department_group_detail",
    component: department_group_detail,
    meta: {
      title: "分组详情",
    },
  },
  {
    path: "/grouping_admin",
    component: grouping_admin,
    meta: {
      title: "分组管理",
    },
  },
  {
    path: "/grouping_detail",
    component: grouping_detail,
    meta: {
      title: "分组详情",
    },
  },
  {
    path: "/company_portrait",
    component: company_portrait,
    meta: {
      title: "公司画像",
    },
  },
  {
    path: "/department_portrait",
    component: department_portrait,
    meta: {
      title: "部门画像",
    },
  },
  {
    path: "/grouping_portrait",
    component: grouping_portrait,
    meta: {
      title: "分组画像",
    },
  },
  {
    path: "/person_portrait",
    component: person_portrait,
    meta: {
      title: "个人画像",
    },
  },
  {
    path: "/staff_portrait",
    component: staff_portrait,
    meta: {
      title: "员工画像",
    },
  },
  {
    path: "/create_department",
    component: create_department,
    meta: {
      title: "创建部门",
    },
  },
  {
    path: "/invite_to_join",
    component: invite_to_join,
    meta: {
      title: "邀请加入",
    },
  },
  {
    path: "/invite_code",
    component: invite_code,
    meta: {
      title: "邀请码",
    },
  },
  {
    path: "/create_group",
    component: create_group,
    meta: {
      title: "创建分组",
    },
  },
  {
    path: "/face_to_face",
    component: face_to_face,
    meta: {
      title: "面对面新增客户",
    },
  },
  {
    path: "/long_distance",
    component: long_distance,
    meta: {
      title: "远程新增客户",
    },
  },
  {
    path: "/take_delivery_detail",
    component: take_delivery_detail,
    meta: {
      title: "收货信息",
    },
  },
  {
    path: "/logistics_detail",
    component: logistics_detail,
    meta: {
      title: "物流信息",
    },
  },
  {
    path: "/client_admin",
    component: client_admin,
    meta: {
      title: "客户管理",
    },
  },
  {
    path: "/client_portrait",
    component: client_portrait,
    meta: {
      title: "客户画像",
    },
  },
  {
    path: "/edit_password",
    component: edit_password,
    meta: {
      title: "修改密码",
    },
  },
  {
    path: "/edit_user_detail",
    component: edit_user_detail,
    meta: {
      title: "修改个人信息",
    },
  },
  {
    path: "/user_info",
    component: user_info,
    meta: {
      title: "修改用户职级",
    },
  },
  {
    path: "/login",
    component: login,
    meta: {
      title: "登录",
    },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 500);
      });
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
