import {
  hex_md5
} from "@/utils/md5";
export function create_post_data(data) {
  if (!data) {
    data = {};
  }
  data.timestamp = parseInt(new Date().getTime() / 1000);
  console.log(data);
  let sign = signature(data);
  data.sign = sign;
  return data;
}

export function create_search_param(data, isfirst) {
  if (!data) {
    data = {};
  }
  let string = "";
  let num = 0;
  data.timestamp = parseInt(new Date().getTime() / 1000);
  let sign = signature(data);
  data.sign = sign;
  for (let key in data) {
    if (num == 0 && isfirst) {
      string += "?" + key + "=" + data[key];
    } else {
      string += "&" + key + "=" + data[key];
    }
    num++;
  }
  return string;
}

export function signature(data) {
  delete data.sign;
  var n = null,
    d = {},
    str = "",
    s = "";
  n = Object.keys(data).sort();
  for (var i in n) {
    d[n[i]] = data[n[i]];
  }
  for (var k in d) {
    if (typeof (d[k]) == 'object') continue
    if (typeof (d[k]) == 'undefined') continue
    if (d[k] === "") continue;
    if (str != "") str += "&";
    str += k + "=" + encodeURI(d[k]);
  }
  str += "&key=huarx2022";
  s = hex_md5(str).toUpperCase(); // 这儿是进行MD5加密并转大写
  return s;
}
