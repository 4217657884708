import { wechat_oauth_face_to_face } from "@/api/wechat";

const state = {};
const mutations = {};
const actions = {
  // 职级列表
  wechat_oauth_face_to_face({ commit }) {
    return new Promise((resolve, reject) => {
      wechat_oauth_face_to_face()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
