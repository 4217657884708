import { send_sms } from "@/api/sms";

const state = {};
const mutations = {};
const actions = {
  // 职级列表
  send_sms({ commit }, param) {
    return new Promise((resolve, reject) => {
      send_sms(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
