import Cookies from 'js-cookie'

const TokenKey = 'token'
const RolesKey = 'Agent-Roles'
const MacineStateKey = 'bind_machine_state'
export function getToken() {
  return Cookies.get(TokenKey)
}
export function getMacineState() {
  return Cookies.get(MacineStateKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setMachineToken(bind_machine_state) {
  return Cookies.set(MacineStateKey, bind_machine_state)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function removeMachineToken() {
  return Cookies.remove(MacineStateKey)
}

export function getRoles() {
  return Cookies.get(RolesKey)
}

export function setRoles(roles) {
  return Cookies.set(RolesKey, roles)
}

export function getAccount() {
  return Cookies.get(AccountKey)
}

export function setAccount(account) {
  return Cookies.set(AccountKey, account)
}
