import {
    userinfo,
    edit_info,
    update_password,
    user_owner_info,
    user_machine_statistic,
    user_machine_bar_statistic,
    user_trade_statistic,
    user_trade_statistic_new,
    user_trade_line_statistic,
    invite_qr_code,
    userinfoById,
    change_user_info,
} from "@/api/userinfo";

const state = {};
const mutations = {};
const actions = {
    // 用户信息
    userinfo({ commit }) {
        return new Promise((resolve, reject) => {
            userinfo()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    userinfoById({ commit }, param) {
        return new Promise((resolve, reject) => {
            userinfoById(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    change_user_info({ commit }, param) {
        return new Promise((resolve, reject) => {
            change_user_info(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 修改用户信息
    edit_info({ commit }, param) {
        return new Promise((resolve, reject) => {
            edit_info(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 修改密码
    update_password({ commit }, param) {
        return new Promise((resolve, reject) => {
            update_password(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-个人信息
    user_owner_info({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_owner_info(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-机器统计
    user_machine_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_machine_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-机器统计图表数据
    user_machine_bar_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_machine_bar_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-交易统计
    user_trade_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_trade_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-交易统计
    user_trade_statistic_new({ commit }, param) {
        return new Promise((resolve, reject) => {
          user_trade_statistic_new(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-交易统计
    user_trade_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_trade_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-交易统计图表数据
    user_trade_line_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            user_trade_line_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 员工画像-生成邀请二维码
    invite_qr_code({ commit }, param) {
        return new Promise((resolve, reject) => {
            invite_qr_code(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
