import request from "@/utils/request";

export function company_owner_info() {
    return request({
        url: "/api/company_owner_info",
        method: "get",
    });
}

export function company_machine_statistic(data) {
    return request({
        url: "/api/company_machine_statistic",
        method: "get",
        data: data,
    });
}

export function company_machine_bar_statistic(data) {
    return request({
        url: "/api/company_machine_bar_statistic",
        method: "get",
        data: data,
    });
}

export function company_trade_statistic(data) {
    return request({
        url: "/api/company_trade_statistic",
        method: "get",
        data: data,
    });
}

export function company_trade_statistic_new(data) {
    return request({
        url: "/api/company_trade_statistic_new",
        method: "get",
        data: data,
    });
}

export function company_trade_line_statistic(data) {
    return request({
        url: "/api/company_trade_line_statistic",
        method: "get",
        data: data,
    });
}
