import request from "@/utils/request";

export function userinfo() {
  return request({
    url: "/api/user/info",
    method: "get",
  });
}

export function userinfoById(data) {
  return request({
    url: "/api/user/user_info",
    method: "get",
    data,
  });
}

export function change_user_info(data) {
  return request({
    url: "/api/user/change_user_info",
    method: "post",
    data,
  });
}

export function edit_info(data) {
  return request({
    url: "/api/user/edit_info",
    method: "post",
    data: data,
  });
}

export function update_password(data) {
  return request({
    url: "/api/update/update_password",
    method: "post",
    data: data,
  });
}

export function user_owner_info(data) {
  return request({
    url: "/api/user_owner_info",
    method: "get",
    data: data,
  });
}

export function user_machine_statistic(data) {
  return request({
    url: "/api/user_machine_statistic",
    method: "get",
    data: data,
  });
}

export function user_machine_bar_statistic(data) {
  return request({
    url: "/api/user_machine_bar_statistic",
    method: "get",
    data: data,
  });
}

export function user_trade_statistic(data) {
  return request({
    url: "/api/user_trade_statistic",
    method: "get",
    data: data,
  });
}

export function user_trade_statistic_new(data) {
  return request({
    url: "/api/user_trade_statistic_new",
    method: "get",
    data: data,
  });
}

export function user_trade_line_statistic(data) {
  return request({
    url: "/api/user_trade_line_statistic",
    method: "get",
    data: data,
  });
}

export function invite_qr_code(data) {
  return request({
    url: "/api/invite_qr_code",
    method: "get",
    data: data,
  });
}
