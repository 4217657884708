import { rank_list } from "@/api/rank";

const state = {};
const mutations = {};
const actions = {
  // 职级列表
  rank_list({ commit }, param) {
    return new Promise((resolve, reject) => {
      rank_list(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
