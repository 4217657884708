import request from '@/utils/request'

export function uploadImg(data) {
  return request({
    url: '/api/upload',
    method: 'post',
    headers:{"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"},
    data
  })
}
