import request from "@/utils/request";

export function department_list() {
    return request({
        url: "/api/department_list",
        method: "get",
    });
}

export function department_group_user_list() {
    return request({
        url: "/api/department_group_user_list",
        method: "get",
    });
}

export function department_type_list() {
    return request({
        url: "/api/department_type_list",
        method: "get",
    });
}

export function department_owner_info(data) {
    return request({
        url: "/api/department_owner_info",
        method: "get",
        data: data,
    });
}

export function department_machine_statistic(data) {
    return request({
        url: "/api/department_machine_statistic",
        method: "get",
        data: data,
    });
}

export function department_machine_bar_statistic(data) {
    return request({
        url: "/api/department_machine_bar_statistic",
        method: "get",
        data: data,
    });
}

export function department_trade_statistic(data) {
    return request({
        url: "/api/department_trade_statistic",
        method: "get",
        data: data,
    });
}

export function department_trade_statistic_new(data) {
    return request({
        url: "/api/department_trade_statistic_new",
        method: "get",
        data: data,
    });
}

export function department_trade_line_statistic(data) {
    return request({
        url: "/api/department_trade_line_statistic",
        method: "get",
        data: data,
    });
}

export function add_department(data) {
    return request({
        url: "/api/add_department",
        method: "post",
        data: data,
    });
}
