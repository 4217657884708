import request from "@/utils/request";

export function group_list(data) {
    return request({
        url: "/api/group_list",
        method: "get",
        data,
    });
}

export function group_user_list() {
    return request({
        url: "/api/group_user_list",
        method: "get",
    });
}

export function group_owner_info(data) {
    return request({
        url: "/api/group_owner_info",
        method: "get",
        data,
    });
}

export function group_users(data) {
    return request({
        url: "/api/group_users",
        method: "get",
        data: data,
    });
}

export function group_machine_statistic(data) {
    return request({
        url: "/api/group_machine_statistic",
        method: "get",
        data: data,
    });
}

export function group_machine_bar_statistic(data) {
    return request({
        url: "/api/group_machine_bar_statistic",
        method: "get",
        data: data,
    });
}

export function group_trade_statistic(data) {
    return request({
        url: "/api/group_trade_statistic",
        method: "get",
        data: data,
    });
}

export function group_trade_statistic_new(data) {
    return request({
        url: "/api/group_trade_statistic_new",
        method: "get",
        data: data,
    });
}

export function group_trade_line_statistic(data) {
    return request({
        url: "/api/group_trade_line_statistic",
        method: "get",
        data: data,
    });
}

export function add_group(data) {
    return request({
        url: "/api/add_group",
        method: "post",
        data: data,
    });
}
