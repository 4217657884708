import request from "@/utils/request";

export function customer_total(data) {
    return request({
        url: "/api/customer_total",
        method: "get",
        data: data,
    });
}
export function customer_list(page, data) {
    data["page"] = page;
    return request({
        url: "/api/customer_list",
        method: "get",
        data: data,
    });
}

export function customer_info(data) {
    return request({
        url: "/api/customer_info",
        method: "get",
        data: data,
    });
}

export function customer_log(data) {
    return request({
        url: "/api/customer_log",
        method: "get",
        data: data,
    });
}

export function customer_trade_statistic(data) {
    return request({
        url: "/api/customer_trade_statistic",
        method: "get",
        data: data,
    });
}

export function customer_trade_statistic_new(data) {
    return request({
        url: "/api/customer_trade_statistic_new",
        method: "get",
        data: data,
    });
}

export function customer_trade_line_statistic(data) {
    return request({
        url: "/api/customer_trade_line_statistic",
        method: "get",
        data: data,
    });
}

export function add_customer(data) {
    return request({
        url: "/api/add_customer",
        method: "post",
        data: data,
    });
}
