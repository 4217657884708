import {
    company_owner_info,
    company_machine_statistic,
    company_machine_bar_statistic,
    company_trade_statistic,
    company_trade_statistic_new,
    company_trade_line_statistic,
} from "@/api/company";

const state = {};
const mutations = {};
const actions = {
    // 公司画像-公司负责人信息
    company_owner_info({ commit }) {
        return new Promise((resolve, reject) => {
            company_owner_info()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 公司画像-机器统计
    company_machine_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            company_machine_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 公司画像-机器统计图表数据
    company_machine_bar_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            company_machine_bar_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 公司画像-交易统计
    company_trade_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            company_trade_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 公司画像-交易统计
    company_trade_statistic_new({ commit }, param) {
        return new Promise((resolve, reject) => {
            company_trade_statistic_new(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 公司画像-交易统计图表数据
    company_trade_line_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            company_trade_line_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
