import {
    customer_total,
    customer_list,
    customer_info,
    customer_log,
    customer_trade_statistic,
    customer_trade_statistic_new,
    customer_trade_line_statistic,
    add_customer,
} from "@/api/customer";

const state = {};
const mutations = {};
const actions = {
    // 客户统计
    customer_total({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_total(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户列表
    customer_list({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_list(param.page, param.data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户详情
    customer_info({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_info(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户日志
    customer_log({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_log(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户画像-客户交易信息
    customer_trade_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_trade_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户画像-客户交易信息
    customer_trade_statistic_new({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_trade_statistic_new(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 客户画像-客户交易图表信息
    customer_trade_line_statistic({ commit }, param) {
        return new Promise((resolve, reject) => {
            customer_trade_line_statistic(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    // 新增客户
    add_customer({ commit }, param) {
        return new Promise((resolve, reject) => {
            add_customer(param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
