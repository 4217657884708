import {
  department_list,
  department_group_user_list,
  department_type_list,
  department_owner_info,
  department_machine_statistic,
  department_machine_bar_statistic,
  department_trade_statistic,
  department_trade_statistic_new,
  department_trade_line_statistic,
  add_department,
} from "@/api/department";

const state = {};
const mutations = {};
const actions = {
  // 部门列表-用于邀请员工
  department_list({ commit }) {
    return new Promise((resolve, reject) => {
      department_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门列表-用于管理员工
  department_group_user_list({ commit }) {
    return new Promise((resolve, reject) => {
      department_group_user_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门类型列表
  department_type_list({ commit }) {
    return new Promise((resolve, reject) => {
      department_type_list()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-部门负责人个人信息
  department_owner_info({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_owner_info(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-机器统计
  department_machine_statistic({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_machine_statistic(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-机器统计图表数据
  department_machine_bar_statistic({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_machine_bar_statistic(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-交易统计
  department_trade_statistic({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_trade_statistic(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-交易统计
  department_trade_statistic_new({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_trade_statistic_new(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 部门画像-交易统计图表数据
  department_trade_line_statistic({ commit }, param) {
    return new Promise((resolve, reject) => {
      department_trade_line_statistic(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 新增部门
  add_department({ commit }, param) {
    return new Promise((resolve, reject) => {
      add_department(param)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
